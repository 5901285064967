import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {Row, Col, SubTitle} from '../theme'
import Messages from  "./messages"
import {GiHamburgerMenu as MenuLogo} from 'react-icons/gi'
import {ImCross as CloseLogo} from "react-icons/im"
import Nav from "./nav"
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"

const Header = ()=>{
    const [settings, menu] = useSelector(state => [state.data.settings, state.data.menu]);
    let history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const toggle = ()=>{
        dispatch({type: DATA_ACTIONS.SET_MENU, menu: !menu});
    };

    if (!settings){
        return null;
      }
        return <div>
             <div className="MenuContainer" onClick={toggle}>
                {menu?<CloseLogo className="Menu" /> : <MenuLogo className="Menu"/>}
            </div>
            <div className="HeaderContainer">
            {menu? 
                <Nav mobile /> : 
                <div>
                    <div className="headerImage">
                        <img src={settings.img} className="ResponsiveImage" style={{cursor: "pointer"}} onClick={()=>{
                            if (location.pathname == "/"){
                                toggle();
                            } else {
                                history.push("/")
                            }
                        }} />
                    </div>
                    <div className="headerImage MessageContainer">
                        <Messages />
                    </div>
                </div>
              }
    </div>
        </div>
        
        
}

export default Header;