import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import BlockContent from '@sanity/block-content-to-react';
import { Col, Row, Text, SubTitle, Button, Title} from '../theme'
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText'
import MemeViewer from './memeViewer';

const About = ()=>{
    const settings = useSelector(state => state.data.settings);

    useEffect(()=>{
        
    })

    if (!settings){
        return null;
    }

    return <>
    
    
    <MetaTags>
                 <title>{ `${settings.title} - about`}</title>
                 <meta name="description" content={BlocktoPlainText(settings.intro)} />
                <meta property="og:title" content={settings.title} />
                <meta property="og:image" content={settings.url+"?w=720"} />  
            </MetaTags>
            <Row>
            <Col xs={12} sm={12} md={false}> 
                <br/><br/>
                </Col>
              <Col xs={false} sm={false} md={5}>
                <MemeViewer amount={3} auto />
              </Col>
              <Col xs={12} sm={12} md={7}>
                <Text>
                  <BlockContent blocks={settings.about.intro} />
                </Text>
                <br/>
                <img className="ResponsiveImage" src={settings.about.img} />
                <br/><br/>
                {
                  settings.about.second?<Text>
                  <BlockContent blocks={settings.about.second} />
                </Text>:null
                }

                {
                  (settings.about.members && settings.about.members.length)?<>
                  <br/><br/>
                  <Title>MEME Members</Title>
                  <br/>
                  
                  {settings.about.members.map(({_key, bio, member, img})=><Row key={_key} style={{paddingBottom: "20px"}}>
                    <Col xs={3}>
                      <img style={{width: "100%", borderRadius: "50%"}} src={img} />
                    </Col>
                    <Col xs={8} style={{paddingLeft: "20px"}}>
                      <Text> 
                        <b>{member}</b>
                      </Text>
                      <i><BlockContent blocks={bio} /></i>
                      
                    </Col>
                    </Row>)}
                  

                  </>:null
                }

                <br/>
                <a href={"mailto:"+settings.about.mail}> <Button text="get in touch" noUrl/> </a>
                
                {(settings.about.partners && settings.about.partners.length)?<>
                <br/><br/>
                <SubTitle>Partners</SubTitle>
                <br/>
                <Col xs={12} sm={10} md={6} style={{background: "white"}}>
                <br/><br/>
                    <Row center="xs"> 
                        
                {settings.about.partners.map(({_key, description, img, url})=><Col key={_key} xs={12} sm={7}>
                    <a href={url} target="_blank">
                    <img className="ResponsiveImage" src={img} />
                    </a>
                    <br/><br/><br/>
                </Col>)}
                </Row>
                </Col>
                </>:null}
                
                {(settings.about.schools && settings.about.schools.length)?<>
                <br/><br/>
                <SubTitle>Schools</SubTitle>
                <br/>
                <Col xs={12} sm={10} md={6} style={{background: "white"}}>
                <br/><br/>
                    <Row center="xs"> 
                        
                {settings.about.schools.map(({_key, description, img, url})=><Col key={_key} xs={12} sm={7}>
                    <a href={url} target="_blank">
                    <img className="ResponsiveImage" src={img} />
                    </a>
                    <br/><br/><br/>
                </Col>)}
                </Row>
                </Col> 
                </>: null}
                
              </Col>
            </Row>
            
    
    </>
  }

export default About;