import React, {useState, useEffect, useRef} from "react"
import { useSelector } from "react-redux";
import { Col, Row } from "react-styled-flexboxgrid";
import {Text, Button} from "../theme"
import IntersectionVisible from 'react-intersection-visible';


const MemeViewer = ({amount, list, more, responsive, action, id, tags, auto})=>{
    const [settings, memes] = useSelector(state=>[state.data.settings, state.data.memes]);

    const [prevList, setPrevList] = useState(list);
    const [selection, setSelection] = useState(null);
    const [target, setTarget] = useState(null);
    const [targetAmount, setTargetAmount] = useState(amount || 3);
    const myRef = useRef(null)
    
  
  

    // console.log(list);

    const adder = 3;

    const getMeme=(_ref)=>{
        let rv = 0;
        memes.forEach((m,i)=>{
            if (_ref == m._id){
                rv = i;
            }
        })
        return rv;
    }

    const populate = ()=>{
        let newlist = selection?[...selection]:[];

        

        if (target && myRef){
            setTimeout(()=>{
                if (myRef && myRef.current){
                    myRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            }, 200)
            setTarget(null);
        }

        if (list){
            newlist = ([...list]).slice(0, Math.min(targetAmount, list.length)).map(({_ref})=>getMeme(_ref));

            setTarget(newlist[newlist.length-adder]);
        } else {
            let setFirst = false;
            while(newlist.length < targetAmount){
                const newindex = Math.floor(Math.random()*memes.length);
                if (!newlist.includes(newindex) && memes[newindex] && memes[newindex].display){
                    newlist.push(newindex);
                    if(!setFirst){
                        setTarget(newindex);
                        setFirst = true;
                    }
                }
            }
        }

        setSelection(newlist);
    }

    useEffect(()=>{

        
        if (memes && !selection){
            populate()
        } else if (list && list!==prevList){
            setTargetAmount(amount);
            setPrevList(list);
            populate();
            setTarget(null);
        } else if (selection && (selection.length < targetAmount)){
            if (list && list.length < targetAmount){
                // populate()
            } else {
                populate()
            }
        }
    });

    if (!settings || !memes || !selection){
        return null;
    }

    
    

    return <Row>
            {selection.map((idx)=>{
            const {author, description, edition, email, img, _id} = memes[idx];

            if (!img){
                return null;
            }

            return <Col key={_id} xs={12} sm={responsive?6:12} md={responsive?4:12} lg={responsive?4:12} ref={(idx==target)?myRef:null}  >
             <div  className="MemeCard" >
                <img className="ResponsiveImage" src={img} />
                <Text inverted>
                    {description?description:null} {author?<>- {author}</>:null}  <br/>
                    <i>{edition? <> {edition} edition </>:null}</i> 
                </Text>
                {action? <Button invert text="toggle display" noUrl  action={()=>{action(memes[idx])}} />: null}
                {id? <Button text="edit" invert url={`https://studio.massiveexoplanetmemeexhibition.com/desk/meme;${_id}`} external />: null}
            </div>
                
            </Col>
        })}
        <Col xs={12}> 
        {more?<Button text="More" noUrl action={()=>{
            setTargetAmount(targetAmount+adder);
        }} />:null}

        {auto?<IntersectionVisible onShow={()=>{
            setTargetAmount(targetAmount+adder);
        }}>
                    <div>
                        &nbsp;
                    </div>
                </IntersectionVisible> : null}        
        </Col>
        </Row>
    
}

export default MemeViewer;