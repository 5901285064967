import React, {useState, useCallback, useEffect} from 'react'
import {useDropzone} from 'react-dropzone'
import {Text, Button} from '../theme';


const ImageDrop = ({setTitle, setValue, text, setReset, reset})=>{
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                setTitle(file.name);
                setValue(reader.result);
            }
            reader.readAsArrayBuffer(file)
            setFiles([acceptedFiles[0]]);
        })
        }, [])

        const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
        const [files, setFiles] = useState(null);
        text = text || "Drag & drop the meme here, or click to select an image";

        useEffect(()=>{
            if (!reset){
                setReset(()=>{
                    setFiles(null);
                })
            }
        });

        const getImageContent = ()=>{
            if (files === null){
                return <div {...getRootProps()} className="drop">
                        <input {...getInputProps()} />
                            {
                                isDragActive ?
                                <p>Drop the image here ...</p> :
                                <p>{text}</p>
                            }
                        </div>
            } else {
                return <>
                            {files.map((file)=>{return<>  <Text>{file.name}</Text></>})}
                            <br/>
                            <Button text='remove' noUrl action={()=>{setFiles(null); setValue(null); setTitle(null)}} />
                </>
            }
        }

        return getImageContent()
}

export default ImageDrop;