import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import {Row, Col, Grid, Text} from '../theme'
import Nav from './nav'

var style = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
}

var phantom = {
  display: 'block',
  padding: '20px',
  height: '60px',
  width: '100%',
}


function Footer() {
    const settings = useSelector(state => state.data.settings);

    if (!settings){
        return null;
    }

    const mail = settings.about.mail;



    return (
        <div>
            <div style={phantom} />
            <div className="OnlyMobile">
                <Nav />
            </div>
            <div className="Footer">
                 <Grid style={{width: "100%", padding: 0, margin: 0}}>
                     <Row center="xs">

                         {/* <Col xs={12} sm={false} md={12}> 
                            &nbsp;
                         </Col> */}
                        

                         <Col xs={false} sm={false} md={11}>
                             <br/>

                         <Text inverted style={{textAlign:"center"}}>
                            &nbsp;&nbsp;&nbsp;&nbsp;Copyright © 2024 MEME, <a href={"mailto:"+mail}> {mail} </a>

                            <br className="mobile-break" />
                            <br className="mobile-break" />
                            <br className="mobile-break" />


                            <span style={{paddingRight: "100px"}}> 
                            {settings.socials.map(({img, url})=> <a key={url} href={url} target="_blank"> <img className="socials" src={img} /> </a>)}
                            </span>
                        </Text>

                         </Col>

                         <Col xs={12} sm={12} md={false}>
                             <Row center="xs" >
                                 <Col xs={12} >

                                    <div style={{textAlign: "center", padding: "10px"}} className="PInverted"> 
                                        <br/>
                                        Copyright © 2023 MEME, <a href={"mailto:"+mail}> {mail} </a>

                                    </div>
                                    <br/>
                                </Col>
                             </Row>
                             <Row center="xs">
                                {settings.socials.map(({img, url})=> <Col key={url} xs={2} sm={1} md={1}> <a  href={url} target="_blank"> <img className="ResponsiveImage" src={img} /> </a> </Col>)}
                             </Row>
                         </Col>
                     </Row>
                
                </Grid>
            
            <br/>
            </div>
        </div>
    )
}

export default Footer;