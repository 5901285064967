import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import {Row, Col, SubTitle} from '../theme'

const Messages = ()=>{
    const settings = useSelector(state => state.data.settings);
   // settings.live = true;

    const getMoment = ()=>{
        return new Date(settings?settings.moment:null)
    }

    if (!settings){ return null; }
        const d = getMoment();
        return <div >
            {
                !settings.live? <div >
                <div className="Message">
                        {settings.idletext}
                </div>

                {/* <div className="Message" onClick={()=>{
                    window.open("http://www.google.com", '_blank').focus();
                }} style={{cursor: "pointer"}}>
                    GET YOUR FREE TICKETS HERE
                </div> */}
                
            </div> : <div className="Message">
                        <a href={settings.exhibitionurl}>WE ARE LIVE&nbsp;<a style={{fontSize: "150%"}}>☞</a>&nbsp;VISIT EXHIBITION</a>
                        
                </div>
            }   
        </div>
}

export default Messages;