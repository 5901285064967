import sanityClient from '@sanity/client'
import envi from './environment'
import {settings} from './connector_settings.json'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
// import toast from '../services/toast'
import imageUrlBuilder from '@sanity/image-url'

const prevFetched = {};

export const client = (dispatch)=>{
    const environment = envi();
    let _ = settings;
    let news = [];
    const sc = sanityClient({
        projectId: _.projectId,
        dataset: _.dataset,
        token: _.token, // or leave blank to be anonymous user
        ignoreBrowserTokenWarning: true,
        useCdn: false
    })

    const fetch = (query)=>{
        return new Promise((resolve, reject)=>{
            if (prevFetched[query]){
                if (environment.dev){
                    console.log("reused cached query");
                }
                resolve(prevFetched[query]);
            } else {
                sc.fetch(query)
                .then((data)=>{
                    prevFetched[query] = data;
                    resolve(data);
                }).catch(reject);
            }
        })
    }

    const builder = imageUrlBuilder(sc)

    const squareImage = (url, width)=>{
        return builder.image(url).width(width).height(width).url();
    }

    
    if (environment.dev){
        environment.printstatus()
    } 

    const getUrl = (selector)=>{
        return `"img" : ${selector}.asset->url`
    }

    const fetchMemes = ()=>{
        fetch(`*[_type == "meme"]{
            _id,
            display,
            edition,
            author,
            email,
            description,
            ${getUrl("img")},
            taxonomy[]->{_id, name}
        } | order(_updatedAt desc) `).then((memes)=>{
            memes.forEach((m)=>{
                // console.log(m.taxonomy);
            })
            dispatch({type: DATA_ACTIONS.SET_MEMES, memes});
        })
    };
    fetchMemes();

    const fetchTags = ()=>{
        fetch(`*[_type == "tag"]{
            _id,
            name,
        } | order(_updatedAt desc) `).then((tags)=>{
            tags.forEach((m)=>{
                // console.log(tags);
            })
            dispatch({type: DATA_ACTIONS.SET_TAGS, tags});
        })
    };
    fetchTags();
    


    // const settingsQuery = `*[_type == siteSettings][0]{
    //     live,
    //     password,
    //     tagline,
    //     title,
    //     moment
    //     upload

    // }`;
    const settingsQuery = `*[_type == "siteSettings"][0]{
        title,
        idletext,
        exhibitionurl,
        tagline,
        moment,
        upload,
        mentionablememers,
        intro,
        live,
        allmemes,
        password,
        homememes,
        ${getUrl("logo")},
        socials[]{
            url,
            ${getUrl("img")}
        },
        about {
            ${getUrl("image")},
            intro,
            second,
            mail,
            partners[]{
                _key,
                description,
                url,
                ${getUrl("logo")}
            },
            schools[]{
                _key,
                description,
                url,
                ${getUrl("logo")}
            }, members[]{
                _key,
                ${getUrl("avatar")},
                bio,
                member
            }
        },
        editions[]{
            _key,
            description,
            edition,
            images[]{
                description,
                ${getUrl("img")}
            },
            url,
            featured[]{
                "_ref" : _ref
             }
        },
        education {
            description,
            url,
            tour {
                description, 
                url,
                links[]{
                    title,
                    description,
                    url
                }
            },
            activities[]{
                title,
                ${getUrl("img")},
                description,
                "file" : file.asset->url
            }, 
            guides[]{
                title,
                "url" : manuscript.asset->url
              },
            "student" : student.description,
            "prevschools" : schools.description
        },
        schedule {
            description,
            segments[]{
                _key,
                color,
                title,
                url,
                ${getUrl("eventlist")}
            }
        },
        selectedTags

    }`;
    // console.log(settingsQuery)
    fetch(settingsQuery)
    .then(settings=>{
        setTimeout(()=>{
            // console.log(settings.about);
        }, 500)
        dispatch({type: DATA_ACTIONS.SET_SETTINGS, settings});
    })

    const uploadImage = (filename, blob)=>{
        return sc.assets.upload('image', blob, {contentType: 'image/png', filename})
    }

    const makeImageReference = (_ref)=>{
        return  {
            _type: 'image',
            asset : {
                _type: "reference",
                _ref
            }
        }
    }

    const uploadMeme = (title, author, email, value)=>{
        uploadImage(title+author+email, value)
        .then((document)=>{
            const img = makeImageReference(document._id);
            sc.create({
                _type : "meme",
                author,
                email, 
                img,
                display: false,
                description: title,
                edition: "2022"
            })

        }).catch(e=>console.log(e));
    }

    const updateMeme = (_id, display)=>{
        sc.patch(_id).set({display}).commit().then(()=>{
            // window.location.reload();
        })
        
    }

    // about
    // fetch('*[_type == "siteSettings"][0]{about, title, "default":default.asset->url}').then((settings)=>{
    //   dispatch({type: DATA_ACTIONS.SET_SETTINGS, settings});
    // })

    // fetch('*[_type == "portfolio"][0]{projects[]->{title, "slug":slug.current, description, "cover":cover.asset->url, "images":images[].asset->url}}').then(({projects})=>{
    //     dispatch({type: DATA_ACTIONS.SET_PROJECTS, projects});
    // })

    // title, description, 
    // fetch("*[_type == 'biomoddnews']{title, description, images[]{title, description, 'image':image.asset->url}} | order(_createdAt desc)").then((news)=>{
    //     console.log(news)
    //     dispatch({ type: DATA_ACTIONS.SET_NEWS, news});
    // })

    

    return {
        fetch,
        environment,
        squareImage,
        uploadMeme,
        updateMeme
    }
}



export default client;

