import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {Button} from '../theme'


const Nav = ({mobile})=>{
    const settings = useSelector(state=>state.data.settings);
    const location = useLocation();
    
    const hasLocation = (title)=>{
      return (location.pathname.toLowerCase().includes(title.toLowerCase()))
    }

    useEffect(()=>{
      // console.log(location.pathname);
    })

    if (!settings){
      return null;
    }

    return <div>
      {mobile?<div> 
        <br/><br/>
        <Button text="Home" url="/home" redirect />
      </div> : null}
      <Button text={"Schedule"} url="/schedule" redirect invert={hasLocation("schedule")} />
      <Button text="Memes" url="/memes" redirect invert={hasLocation("memes")} />
      <Button text="Education" url="/education" redirect invert={hasLocation("education")} />
      {/* <Button text="MEME 2022" url="/edition/:2022" redirect invert={hasLocation("edition/:2022")}/>
      <Button text="MEME 2021" url="/edition/:2021" redirect invert={hasLocation("edition/:2021")}/>
      <Button text="MEME 2020" url="/edition/:2020" redirect invert={hasLocation("edition/:2020")}/> */}
      <Button text="About" url="/about" redirect invert={hasLocation("about")}/>
    </div>
        
  
  }

export default Nav;