import React, {useEffect} from 'react';
import {OverviewPage} from './theme'
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import Store from "./Store";
import { createBrowserHistory } from 'history';
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "./reducers/DataReducer"
import sanityclient from './client';
import { Grid, Col, Row} from './theme'

import './assets/fonts/'
// import BuilderTheme from './services/Buildertheme'
import Home from './components/Home'
import Header from './components/header'
import About from './components/about'
import Footer from './components/footer'
import Nav from './components/nav'
import Voter from './components/vote'
import Edition from './components/edition';
import Education from './components/education';
import Schedule from './components/schedule';
import AllMemes from './components/allMemes';


const DataHelper = () => {
  const data = useSelector(state => state.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data.client && dispatch){
      const cli = sanityclient(dispatch);
      dispatch({ type: DATA_ACTIONS.SET_CLIENT, client: cli});
    } 

    if (!data.theme && dispatch ){
      // const builder = new BuilderTheme();
      // dispatch({type: DATA_ACTIONS.SET_THEME, theme: new BuilderTheme()})
    }
  })
  return (
    null
  )
}


const App =()=>{
  const history = createBrowserHistory()
  // const theme = new BuilderTheme();

  return (
    <Provider store={Store}>
    <DataHelper />
      <Router history={history}>

        <Header />
        
        
        
        <Grid style={{minHeight: "85vh"}}>
          <Row center="xs">
            <Col xs={12} sm={12} md={12} lg={12}>
              
              <Row> 
                <Col xs={false} sm={false} md={3} lg={2}>
                  <Nav />
                </Col>
                <Col xs={12} sm={12} md ={9} lg={10}>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/overview" component={OverviewPage} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/vote" component={Voter} />
                    <Route exact path="/edition/:slug" component={Edition} />
                    <Route exact path="/edition" component={Edition} />
                    <Route exact path="/education" component={Education} />
                    <Route exact path="/schedule" component={Schedule} />
                    <Route exact path="/memes" component={AllMemes} />

                    <Route component={Home}/>
                  </Switch>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>

        <Footer />
        
        
      </Router>
  </Provider>
  );
}

export default App;
