import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText'
import { useHistory } from "react-router-dom"
import MemeViewer from './memeViewer';
import {Row, Col, Text, SubTitle, Button, Title} from '../theme';
import BlockContent from '@sanity/block-content-to-react';
import ImageDrop from './imagedrop';


const Education = ()=>{
  const [settings] = useSelector(state => [state.data.settings]);
    
    useEffect(()=>{
    })

    if (!settings){
      return null;
    }
    

    
    return <>
        <MetaTags>
                 <title>{ `${settings.title} - education`}</title>
                 <meta name="description" content={BlocktoPlainText(settings.intro)} />
                <meta property="og:title" content={settings.title} />
                <meta property="og:image" content={settings.url+"?w=720"} />  
            </MetaTags>

            <Row>
            <Col xs={12} sm={12} md={false}> 
                <br/><br/>
                </Col>
              <Col xs={12} sm={12} md={5}>
                {settings.education.activities.map(({title, img, description, file})=><div key={title} style={{padding: "10px"}}>
                    <SubTitle>{title}</SubTitle>
                    <div style={{height: "8px"}} />
                    <img className="ResponsiveImage" src={img} />
                    <Text>
                      <BlockContent blocks={description} />
                    </Text>
                    {/* <Button text="More information" url={file} noMargin/> */}
                    <br />
                  </div>)}
              </Col>
              <Col xs={12} sm={12} md={7}>
                <Text>
                  <BlockContent blocks={settings.education.description} />
                </Text>

                <br/>
                <a href={"mailto:"+settings.about.mail}> <Button text="get in touch" noUrl/> </a>

                <br/>

                <Title>
                  MEME education menu
                </Title>

                {settings.education.guides.map(({title, url})=> <a href={url}> <Button text={title} key={title} noUrl /> </a>)}

                <br/>
                
                <Title>
                  Tours
                </Title>
                <Text>
                  <BlockContent blocks={settings.education.tour.description} />
                </Text>

                <Button text="Sign up" url={settings.education.tour.url} external/>
                <br/>


                {settings.education.tour.links.map(({title, description, url})=><div key={title}>
                  <SubTitle>{title}</SubTitle>
                  <Text>
                  <BlockContent blocks={description} />
                </Text>
                  <br />
                </div>)}

                
                <br/>
                <Title>
                  Are you a student?
                </Title>
                <Text>
                  <BlockContent blocks={settings.education.student} />
                </Text>


                <br/>
                <Title>
                  Participating Schools
                </Title>
                <Text>
                  <BlockContent blocks={settings.education.prevschools} />
                </Text>



              </Col>
            </Row>
    </>
  }

export default Education;