export const ACTIONS = Object.freeze({
    SET_CLIENT: Symbol("data/client"),
    SET_SETTINGS: Symbol('data/settings'),
    SET_MEMES: Symbol("data/memes"),
    SET_MENU: Symbol("ui/menu"),
    SET_TAGS: Symbol("data/tags")
});

const initialState = {
  client: null,
  settings: null,
  memes: null,
  menu: false,
  tags: null
};

export default (state = initialState, action) => {
  
  switch (action.type) {
    case ACTIONS.SET_CLIENT: {
      return {
        ...state,
        client: action.client
      };
    }
    case ACTIONS.SET_SETTINGS: {
      return {
        ...state,
        settings: action.settings
      };
    }
    case ACTIONS.SET_MEMES: {
      return {
        ...state,
        memes: action.memes
      };
    }
    case ACTIONS.SET_MENU: {
      return {
        ...state,
        menu: action.menu
      }
    }
    case ACTIONS.SET_TAGS: {
      return {
        ...state,
        tags: action.tags
      }
    }
    default: {
      return {
        ...state
      };
    }
  } 
};
