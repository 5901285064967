import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-styled-flexboxgrid";
import { Button, SubTitle, Title, Text } from "../theme";
import MemeViewer from "./memeViewer";

const Voter = ()=>{

    const [selection, setSelection] = useState(null);
    const [category, setCategory] = useState("all");
    const [memes, client, settings] = useSelector(state=>[state.data.memes, state.data.client, state.data.settings]);
    const [secure, setSecure] = useState(null);
    const [categories, setCategories] = useState(null);

    useEffect(()=>{
        if (!selection && memes){
            setSelection("all");
            if (!categories){
                const c = [];

                memes.forEach(({category})=>{

                    if (category == undefined){
                        category = "undefined";
                    }

                    if (c.indexOf(category) === -1){
                        c.push(category)
                    }
                })

                setCategories(c);
                console.log(c);
            }
        }

        if (!secure && settings && categories){
            const password = prompt("Password", "");
            setSecure(password == settings.password)
            // setSecure(true);
        }
    })

    if (!memes || !client ||!selection ||!secure){
        return null;
    }
    
    return <Row> 
        <Col xs={6}> 
            <Button text="ALL" noUrl  action={()=>{setSelection("all")}} />
            <Button text="2020" noUrl action={()=>{setSelection("2020")}} />
            <Button text="2021" noUrl action={()=>{setSelection("2021")}} />
            <Button text="2022" noUrl action={()=>{setSelection("2022")}} />
            <Button text="2023" noUrl action={()=>{setSelection("2023")}} />
            <Button text="2024" noUrl action={()=>{setSelection("2024")}} />
            <Button text="2025" noUrl action={()=>{setSelection("2025")}} />
        </Col>
        <Col xs={6}> 
            <Button text="ALL" noUrl  action={()=>{setCategory("all")}} />
            {categories.map((c, i)=><Button key={i} noUrl text={c} action={()=>{setCategory(c)}} /> )}
        </Col>
        <Col xs={12}>

        <Text>
            Note: changes are reflected when you refresh the page, but happen when you click ok.
        </Text>

        <Title>
            {selection} / {category}
        </Title>
        <SubTitle>
            Not accepted
        </SubTitle>
        <MemeViewer id list={
            memes.filter(m=>{
                let rv = ((!m.display && (selection == "all" || m.edition === selection)) && (category == "all" || (m.category == category) || (category == "undefined" && !m.category) ));
                return rv;
            }).map(m=>{
                return {
                    _ref : m._id
                };
            })
        } reset responsive action={(meme)=>{
            const c = window.confirm("Are you sure you want to accept this meme?");
            if (c){
                client.updateMeme(meme._id, true);
            }
        }}/>
        <SubTitle>
            accepted
        </SubTitle>
        <MemeViewer id list={ 
            memes.filter(m=>{
                let rv = ((m.display && (selection == "all" || m.edition === selection)) && (category == "all" || (m.category == category) || (category == "undefined" && !m.category) ));
                return rv;
            }).map(m=>{
                return {
                    _ref : m._id
                };
            })
        } responsive action={(meme)=>{
            const c = window.confirm("Are you sure you want to unaccept this meme?");
            if (c){
                client.updateMeme(meme._id, false);
            }
        }}/>
        
        </Col>
    </ Row>
}

export default  Voter;