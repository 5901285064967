import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import MemeViewer from './memeViewer';
import {Row, Col, Text, SubTitle, Button, Title} from '../theme';
import BlockContent from '@sanity/block-content-to-react';
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText';


const Schedule = ()=>{
  const [settings] = useSelector(state => [state.data.settings]);
    
    useEffect(()=>{
    })

    if (!settings){
      return null;
    }
    

    
    return <>
        <MetaTags>
                 <title>{ `${settings.title} - schedule`}</title>
                 <meta name="description" content={BlocktoPlainText(settings.intro)} />
                <meta property="og:title" content={settings.title} />
                <meta property="og:image" content={settings.url+"?w=720"} />  
            </MetaTags>

            <Row>
                <Col xs={12} sm={12} md={false}> 
                <br/><br/>
                </Col>
              <Col xs={false} sm={false} md={5}>
                <MemeViewer amount={3} auto />
              </Col>
              <Col xs={12} sm={12} md={7}>
              <Title> 
                    Schedule
                </Title>
                <Text>
                  <BlockContent blocks={settings.schedule.description} />
                </Text>

                <br/>

                <Row>

                {settings.schedule.segments.map(({color, img, title, url})=><Col key={img} xs={6}>
                    <img className="ResponsiveImage" src={img} />
                    </Col>)}
                </Row>
                <br/>
              </Col>
              <Col xs={12} sm={12} md={false}>
                <br/><br/>
                <MemeViewer amount={3} more />
              </Col>
            </Row>
    </>
  }

export default Schedule;