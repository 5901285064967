import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import MetaTags from 'react-meta-tags';
import {Row, Col, Text, SubTitle, Button, Title} from '../theme';
import BlocktoPlainText from '../utilities/BlocktoPlainText'
import BlockContent from '@sanity/block-content-to-react';
import MemeViewer from './memeViewer';

const Edition = (props)=>{
    const [settings, memes] = useSelector(state=>[state.data.settings, state.data.memes]);
    const [edition, setEdition] = useState(null);
    // const [amount, setAmount] = useState(3);
    const myRef = useRef();

    useEffect(()=>{
        const s = props.match.params.slug.replace(":", "");
        if ((!edition && settings) || (edition && s!==edition)){
            setEdition(s);
        } 
    })

    if (!settings || !edition || !memes){
        return null;
    }

    const getE = ()=>{
        let rv = settings.editions[0];
        settings.editions.forEach(e=>{
            if (e.edition == edition){
                rv = e;
            }
        })
        return rv;
    }

    const getMemes = ()=>{
        return <>
            <MemeViewer list={getE().featured} amount={getE().featured.length} />
            <MemeViewer amount={3} more list={memes.filter((m, i) => {
                    return m.display && m.edition == edition;
                }).map(m=>({_ref : m._id}))} />
        </>
    }


    return <>
         <MetaTags>
                 <title>{ `${settings.title} - ${edition}`}</title>
                 <meta name="description" content={BlocktoPlainText(settings.intro)} />
                <meta property="og:title" content={ `${settings.title}` - edition} />
                <meta property="og:image" content={settings.url+"?w=720"} />  
            </MetaTags>
            
            <Row center="xs">
            <Col xs={12} sm={12} md={false}> 
                <br/><br/>
                </Col>
                <Col xs={12}>
                    <div style={{textAlign: "center"}}> 
                    <Title>{edition}</Title></div>
                </Col>
            </Row>

            <Row>
              <Col xs={false} sm={false} md={5}>
                {getMemes()}
              </Col>
              <Col xs={12} sm={12} md={7}>
                <Text>
                  <BlockContent blocks={getE().description} />
                </Text>
                {(getE().images && getE().images.length?
                    getE().images.map(({description, img})=><div key={img}> 
                        <img style={{width: "100%"}} src={img} />
                        {description?<Text><BlockContent blocks={description} /></Text>:null}
                    </div>)
                :null)}
              </Col>
              <Col xs={12} sm={12} md={false}>
                  <br/><br/>
                {getMemes()}
              </Col>
            </Row>

    </>
}

export default Edition;