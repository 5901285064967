import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText'
import { useHistory } from "react-router-dom"
import MemeViewer from './memeViewer';
import {Row, Col, Text, SubTitle, Button, Title} from '../theme';
import BlockContent from '@sanity/block-content-to-react';
import ImageDrop from './imagedrop';


const Home = ()=>{
  const [settings, menu, client] = useSelector(state => [state.data.settings, state.data.menu, state.data.client]);
    const history = useHistory();
    const [fileTitle, setFileTitle] = useState(null);
    const [fileValue, setFileValue] = useState(null);
    const [reset, setReset] = useState(null);
    const [texter, setTexter] = useState(null);
    


    useEffect(()=>{
    })

    if (!settings || menu){
      return null;
    }
    

    
    return <>
        <MetaTags>
                 <title>{ `${settings.title}`}</title>
                 <meta name="description" content={BlocktoPlainText(settings.intro)} />
                <meta property="og:title" content={settings.title} />
                <meta property="og:image" content={settings.url+"?w=720"} />  
            </MetaTags>

            <Row>
              <Col xs={false} sm={false} md={5}>
                <Title>&nbsp;Hall of fame</Title>
                <br/>
                <MemeViewer list={settings.homememes} amount={settings.homememes.length} auto />
                <a href="/memes" target="_self"><Button text={"More Memes"} noUrl/></a>
                
              </Col>
              <Col xs={12} sm={12} md={7}>
                <Title>👋 Hello</Title>
                <Text>
                  <BlockContent blocks={settings.intro} />
                </Text>
                <br/>
                <SubTitle>
                  Upload your own meme
                </SubTitle>
                <Text>
                  <BlockContent blocks={settings.upload} />
                </Text>

                {texter? <SubTitle>{texter}</SubTitle>:null}
                
                <ImageDrop setTitle={setFileTitle} setValue={setFileValue} setReset={setReset} reset={reset} />
                {fileValue?<Button text="Proceed" noUrl action={
                  ()=>{
                    if (fileValue){
                      const title = prompt("Please enter a title", "")
                      const person = prompt("Please enter your name or handle", "")
                      const email = prompt("Please enter your preferred email account", "")
                      
                      client.uploadMeme(title, person, email, fileValue);
                      setFileValue(null);
                      setFileTitle(null);
                      if (reset){
                        reset();
                      }
                      setTexter("Your meme was uploaded sucesfully. Noice.");
                    }
                    
                  }
                } />:null}

                <br/><br/><br/>
                <Title>Mentionable Memers</Title>
                <BlockContent blocks={settings.mentionablememers} />


              </Col>
              <Col xs={12} sm={12} md={false}>
                <br/><br/>
                <MemeViewer list={settings.homememes} />
              </Col>
            </Row>
    </>
  }

export default Home;