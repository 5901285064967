import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import MetaTags from 'react-meta-tags';
import {Row, Col, Text, SubTitle, Button, Title} from '../theme';
import BlocktoPlainText from '../utilities/BlocktoPlainText'
import BlockContent from '@sanity/block-content-to-react';
import MemeViewer from './memeViewer';

const AllMemes = (props)=>{
    const [settings, memes, tags] = useSelector(state=>[state.data.settings, state.data.memes, state.data.tags]);
    const [selectedTags, setSelectedTags] = useState([])
    const [tagStatus, setTagStatus] = useState(null)
    const [editionStatus, setEditionStatus] = useState(null);
    

    // const [edition, setEdition] = useState(null);
    // const [amount, setAmount] = useState(3);
    const myRef = useRef();

    useEffect(()=>{
        

        if (!tagStatus && tags && settings){
          const selectedTags_ = [];

          settings.selectedTags.forEach(({_ref})=>{
            tags.forEach((tag) => {
              if (tag._id == _ref){
                selectedTags_.push(tag)
              }
            });
          })

          const ts = {}
          selectedTags_.forEach(tag => {
            ts[tag.name] = false;
          });

          setSelectedTags(selectedTags_);
          setTagStatus(ts);

          const es = {}
          settings.editions.forEach(edition=>{
            es[edition.edition] = false;
          })
        }
    })

    if (!settings || !memes){
        return null;
    }

    const getMemes = ()=>{
        
        let allFalse = true;
        for (const val in tagStatus) {
          if (tagStatus[val]){
            allFalse=false;
          }
        }

        let allFalseEditions = true;
        for (const val in editionStatus) {
          if (editionStatus[val]){
            allFalseEditions=false;
          }
        }


        return <>
            <MemeViewer amount={3} auto list={memes.filter((m, i) => {
                  let intax = false;
                  let incat = false;

                  const checkTax = ()=>{
                    if (m.taxonomy){
                      m.taxonomy.forEach(({_id, name})=>{
                        intax = tagStatus[name] || intax;
                      })
                    } else {
                      intax = false;
                    }
                  }

                  const checkCat = ()=>{
                    incat = editionStatus[m.edition]
                  }


                  if (allFalse && allFalseEditions){
                    intax = true;
                    incat = true;
                  } else if (allFalse && !allFalseEditions){
                    intax = true;
                    checkCat()
                  } else if (!allFalse && allFalseEditions){
                    incat = true;
                    checkTax()
                  } else {
                    checkCat()
                    checkTax()
                  }
                  return m.display && intax && incat;
                }).map(m=>({_ref : m._id}))} />
        </>
    }


    return <>
         <MetaTags>
                 <title>{ `${settings.title} - Memes`}</title>
                 <meta name="description" content={BlocktoPlainText(settings.intro)} />
                <meta property="og:title" content={ `${settings.title}`} />
                <meta property="og:image" content={settings.url+"?w=720"} />  
            </MetaTags>
            
            <Row center="xs">
            <Col xs={12} sm={12} md={false}> 
                <br/><br/>
                </Col>
                <Col xs={12}>
                    <div style={{textAlign: "center"}}> 
                    <Title>MEMES</Title></div>
                </Col>
            </Row>

            <Row>
              <Col xs={false} sm={false} md={5} style={{transform: "translateY(-35px)"}}>
                {getMemes()}
              </Col>
              <Col xs={12} sm={12} md={7}>
                <Text>
                  <BlockContent blocks={settings.allmemes} />
                </Text>

                <br />
                <Title>EDITIONS</Title>
                {settings.editions.map(({edition})=><Button key={edition} noUrl text={edition} toggle action={(state, text)=>{
                  const nes = {...editionStatus};
                  nes[text] = state;
                  setEditionStatus(nes);
                }} />)}
                
                <br/><br/>
                <Title>CATEGORIES</Title>
                {selectedTags.map(({_id, name})=><Button key={_id} noUrl text={name} toggle action={(state, text)=>{
                  const nts = {...tagStatus};
                  nts[text] = state;
                  setTagStatus(nts);
                }} />)}
                

              </Col>
              <Col xs={12} sm={12} md={false}>
                  <br/><br/>
                {getMemes()}
              </Col>
            </Row>

    </>
}

export default AllMemes;